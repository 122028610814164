
        import * as module0 from './controllers/choose_one_controller.js';import * as module1 from './controllers/data_collection_controller.js';import * as module2 from './controllers/details_controller.js';import * as module3 from './controllers/dialog_controller.js';import * as module4 from './controllers/image_rotor_controller.js';import * as module5 from './controllers/machines_overview_controller.js';import * as module6 from './controllers/map/contacts_controller.js';import * as module7 from './controllers/map/world_controller.js';import * as module8 from './controllers/marquee_controller.js';import * as module9 from './controllers/menu_controller.js';import * as module10 from './controllers/scroll_controller.js';import * as module11 from './controllers/slider_controller.js';import * as module12 from './controllers/tagline_rotor_controller.js';import * as module13 from './controllers/title_fixer_controller.js';import * as module14 from './controllers/toggle_class_controller.js'
        const modules = {
            "./controllers/choose_one_controller.js": module0,
            "./controllers/data_collection_controller.js": module1,
            "./controllers/details_controller.js": module2,
            "./controllers/dialog_controller.js": module3,
            "./controllers/image_rotor_controller.js": module4,
            "./controllers/machines_overview_controller.js": module5,
            "./controllers/map/contacts_controller.js": module6,
            "./controllers/map/world_controller.js": module7,
            "./controllers/marquee_controller.js": module8,
            "./controllers/menu_controller.js": module9,
            "./controllers/scroll_controller.js": module10,
            "./controllers/slider_controller.js": module11,
            "./controllers/tagline_rotor_controller.js": module12,
            "./controllers/title_fixer_controller.js": module13,
            "./controllers/toggle_class_controller.js": module14,
        };
        export default modules;
      