import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "image", "name", "technicalName" ]
  static values = {
    interval: {
      type: Number,
      default: 5000
    }
  }

  index = 0
  interval = null

  connect() {
    this.show()

    this.interval = setInterval(() => {
      this.hide()
      this.moveForward()
      this.show()
    }, this.intervalValue)
  }

  disconnect() {
    clearInterval(this.interval)
    this.interval = null
  }

  show() {
    this.image.classList.remove("opacity-0")
    this.name.classList.add("!text-primary")
    this.technicalName.classList.remove("hidden")
  }

  hide() {
    this.image.classList.add("opacity-0")
    this.name.classList.remove("!text-primary")
    this.technicalName.classList.add("hidden")
  }

  moveForward() {
    if ((this.index + 1) > (this.nameTargets.length - 1)) {
      this.index = 0
    } else {
      this.index += 1
    }
  }

  get image() {
    return this.imageTargets[this.index]
  }

  get name() {
    return this.nameTargets[this.index]
  }

  get technicalName() {
    return this.technicalNameTargets[this.index]
  }
}
