import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element"]
  static classes = ["add", "remove"]

  add({ detail }) {
    if (!this.hasAddClass) return

    const element = detail.element || this.element

    this.addClasses.forEach(className => {
      element.classList.add(className)
    })
  }

  remove({ detail }) {
    if (!this.hasRemoveClass) return

    const element = detail.element || this.element

    this.removeClasses.forEach(className => {
      element.classList.remove(className)
    })
  }
}
