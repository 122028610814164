import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button" ]
  static values = {
    ignore: Boolean,
  }

  connect() {
    this.setStatusElements()
  }

  setStatusElements() {
    this.retrieveCurrentStatus()

    if (this.ignoreValue) {
      this.buttonTarget.textContent = "Turn on"
    } else {
      this.buttonTarget.textContent = "Turn off"
    }
  }

  retrieveCurrentStatus() {
    let key = null

    if (typeof localStorage !== "undefined") {
      key = localStorage.getItem("plausible_ignore")
    }

    if (key === "true") {
      this.ignoreValue = true
      return true
    } else {
      this.ignoreValue = false
      return false
    }
  }

  toggleCollection() {
    if (this.ignoreValue) {
      this.optIN()
    } else {
      this.optOUT()
    }

    this.setStatusElements()
  }

  optIN() {
    localStorage.removeItem("plausible_ignore")
  }

  optOUT() {
    localStorage.setItem("plausible_ignore", "true")
  }
}
