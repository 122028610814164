import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["observable"]

  connect() {
    this.observer = new IntersectionObserver(this.present, {
      rootMargin: "0px"
    })

    for (const target of this.observableTargets) {
      this.observer.observe(target)
    }
  }

  present(entries) {
    for (const entry of entries) {
      if (entry.intersectionRatio >= (entry.target.dataset.scrollThreshold || 0.25)) {
        entry.target.dataset.enterClass?.split(" ").forEach(el => {
          entry.target.classList.add(el)
        })
      }
    }
  }
}
