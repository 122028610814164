import { Controller } from "@hotwired/stimulus"
import Swiper, { Autoplay, EffectFade } from "swiper"

import "swiper/css"
import "swiper/css/effect-fade"

export default class extends Controller {
  slider = null

  connect() {
    this.slider = new Swiper(this.element, {
      modules: [Autoplay, EffectFade],

      effect: "fade",
      fadeEffect: {
        crossFade: true
      },
      loop: true,
      autoplay: {
        enabled: true,
        pauseOnMouseEnter: false,
        disableOnInteraction: false,
        delay: 4000
      },
      speed: 800,

      keyboard: false,
      allowTouchMove: false,
      mousewheel: false,
      grabCursor: false,
    })
  }

  disconnect() {
    this.slider.destroy()
    this.slider = null
  }
}
