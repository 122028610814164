import { Controller } from "@hotwired/stimulus"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"

export default class extends Controller {
  static values = {
    coordinates: Object,
    mapboxStyle: String
  }

  connect() {
    mapboxgl.accessToken = "pk.eyJ1IjoidG9tbWFzb25nciIsImEiOiJjazdjM2ZuMXowMG9zM2dxam10eW5qc3BsIn0._qrzztL_YLNvgnFdv89Xew"

    const coordinates = new mapboxgl.LngLat(this.coordinatesValue.longitude, this.coordinatesValue.latitude)
    let firstRun = true

    this.map = new mapboxgl.Map({
      container: this.element,
      style: this.mapboxStyleValue,
      projection: "globe",
      attributionControl: false,

      center: [9, 42],
      zoom: 4,
      pitch: 0,

      maxPitch: 70,

      scrollZoom: false,
      boxZoom: false,
      dragPan: false,
      dragRotate: false,
      touchZoomRotate: false,
      touchPitch: false,
      doubleClickZoom: false,
    })

    this.map.once("moveend", () => {
      this.map.dragRotate.enable()
      this.map.touchPitch.enable()
      this.map.setMinPitch(40)
    })

    this.map.on("load", () => {
      this.map.addSource("building", {
        "type": "geojson",
        "data": {
          "type": "FeatureCollection",
          "features": [
            {
              "type": "Feature",
              "properties": {
                "height": 20
              },
              "geometry": {
                "coordinates": [
                  [
                    [
                      8.927117665243827,
                      45.41264475416031
                    ],
                    [
                      8.92664556832409,
                      45.41324743871877
                    ],
                    [
                      8.926328776928415,
                      45.41312679748384
                    ],
                    [
                      8.926401468772355,
                      45.41303559291674
                    ],
                    [
                      8.926340891474865,
                      45.413013093160316
                    ],
                    [
                      8.926411636058162,
                      45.41292835394225
                    ],
                    [
                      8.926239114424789,
                      45.41286222148034
                    ],
                    [
                      8.926578490380194,
                      45.4124350427106
                    ],
                    [
                      8.927117665243827,
                      45.41264475416031
                    ]
                  ]
                ],
                "type": "Polygon"
              }
            },
            {
              "type": "Feature",
              "properties": {
                "height": 1
              },
              "geometry": {
                "coordinates": [
                  [
                    [
                      8.927174144819986,
                      45.41263501702008
                    ],
                    [
                      8.926630106696848,
                      45.41333405257345
                    ],
                    [
                      8.926261730362029,
                      45.41318671978374
                    ],
                    [
                      8.926230310281852,
                      45.413110748933775
                    ],
                    [
                      8.926153926352868,
                      45.41312466912362
                    ],
                    [
                      8.926033552483915,
                      45.412827940770995
                    ],
                    [
                      8.9264274775729,
                      45.41233907308339
                    ],
                    [
                      8.927174144819986,
                      45.41263501702008
                    ]
                  ]
                ],
                "type": "Polygon"
              }
            }
          ]
        }
      })

      this.map.addLayer({
        "id": "building-extrusion",
        "type": "fill-extrusion",
        "source": "building",
        "minzoom": 15,
        "paint": {
          "fill-extrusion-color": "#4F8129",
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            16,
            ["get", "height"]
          ],
          "fill-extrusion-base": 0,
          "fill-extrusion-opacity": 0.9,
          "fill-extrusion-vertical-gradient": false
        }
      })

      if (!firstRun) return

      this.map.flyTo({
        center: coordinates,
        zoom: 16.5,
        bearing: 110,
        pitch: 70,
        duration: 15000,
        easing: function(x) {
          return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2
        },
        essential: true
      })

      setTimeout(() => {
        firstRun = false
      }, 15000)
    })

    this.resizeObserver = new ResizeObserver(() => {
      if (firstRun) return

      this.map.flyTo({
        center: coordinates,
        zoom: 16.5,
        bearing: 110,
        pitch: 70,
        duration: 2000,
        easing: function(x) {
          return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2
        },
        essential: true
      })

      this.map?.resize()
    })
    this.resizeObserver.observe(this.element)
  }

  disconnect() {
    this.resizeObserver.disconnect()
  }
}
