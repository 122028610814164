import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element"]

  connect() {
    if (this.hasElementTarget) {
      const choice = this.elementTargets[Math.floor(Math.random()*this.elementTargets.length)]

      this.dispatch("choice", { detail: { element: choice } })
    }
  }
}
