import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tag"]
  static values = {
    interval: {
      type: Number,
      default: 3500
    }
  }
  static classes = ["main"]

  index = 0
  interval = null

  connect() {
    this.show()

    this.interval = setInterval(() => {
      this.hide()
      this.moveForward()
      this.show()
    }, this.intervalValue)
  }

  disconnect() {
    clearInterval(this.interval)
    this.interval = null
  }

  show() {
    this.tag.classList.add(this.hasMainClass ? this.mainClass : "tagline-rotor__option--in-out")
  }

  hide() {
    this.tag.classList.remove(this.hasMainClass ? this.mainClass : "tagline-rotor__option--in-out")
  }

  moveForward() {
    if ((this.index + 1) > (this.tagTargets.length - 1)) {
      this.index = 0
    } else {
      this.index += 1
    }
  }

  get root() {
    return this.element
  }

  get tag() {
    return this.tagTargets[this.index]
  }
}
