import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container', 'content']
  static values = {
    direction: {
      type: String,
      default: 'left',
    },
    duration: {
      type: Number,
      default: 10,
    },
    customSpacing: String,
    pauseOnHover: {
      type: Boolean,
      default: false,
    },
  }

  initialize() {
    this.direction = this.directionValue === 'left' ? 'normal' : 'reverse'
    this.timer = null

    if (this.pauseOnHoverValue) {
      this.containerTarget.classList.add('scrolling-container--pause-on-hover')
    }
  }

  connect() {
    this.makeMarquee()

    window.addEventListener('resize', this.makeMarquee.bind(this))
  }

  disconnect() {
    window.removeEventListener('resize', this.makeMarquee.bind(this))
  }

  setDimensions() {
    this.element.style.height = this.containerTarget.clientHeight + 'px'
  }

  setSpacing() {
    if (this.customSpacingValue) {
      this.contentTargets.forEach((el) => {
        el.classList += ` ${this.customSpacingValue}`
      })
    } else {
      this.contentTargets.forEach((el) => (el.innerHTML += '&nbsp;'))
    }
  }

  setVelocity() {
    this.velocity =
      this.containerTarget.clientWidth / (this.durationValue * 100)
  }

  setAnimation() {
    this.containerTarget.style.animation = `marquee ${this.velocity}s linear infinite ${this.direction}`
  }

  makeMarquee() {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.setSpacing()
      this.setDimensions()

      setTimeout(() => {
        this.setVelocity()

        this.setAnimation()
      }, 500)
    }, 100)
  }
}
