import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize() {
    this.timer = null
  }

  connect() {
    this.performFix()

    window.addEventListener('resize', this.recalcute.bind(this))
  }

  disconnect() {
    window.removeEventListener('resize', this.recalcute.bind(this))
  }

  performFix() {
    const initial = this.element.innerText[0].toLowerCase()
    let shift = 0

    const toFix = new Set([
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      'u',
    ])

    const breakpoints = {
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      '2xl': 1536,
    }

    if (toFix.has(initial)) {
      switch (true) {
        case window.innerWidth > breakpoints.sm:
          shift = -7
          break
        default:
          shift = -3
          break
      }
    }

    if (shift !== 0) {
      this.element.style.transform = `translateX(${shift}px)`
    }
  }

  recalcute() {
    clearTimeout(this.timer)
    this.timer = setTimeout(this.performFix.bind(this), 100)
  }
}
