import { Controller } from "@hotwired/stimulus"
import Swiper, { Lazy, Navigation, Pagination, Autoplay } from "swiper"
import "swiper/css"

export default class extends Controller {
  static targets = ["root", "nextButton", "prevButton", "pagination"]

  static values = {
    autoplay: {
      type: Boolean,
      default: true
    }
  }

  slider = null

  connect() {
    this.slider = new Swiper(this.rootTarget, {
      init: false,
      modules: [Lazy, Navigation, Pagination, Autoplay],
      autoplay: {
        enabled: this.autoplayValue,
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      },
      loop: true,
      autoHeight: true,
      navigation: {
        nextEl: this.hasNextButtonTarget ? this.nextButtonTarget : null,
        prevEl: this.hasPrevButtonTarget ? this.prevButtonTarget : null,
      },
      pagination: {
        el: this.hasPaginationTarget ? this.paginationTarget : null,
        type: "bullets",
        clickable: true,
      },
      lazy: {
        enabled: true,
        loadPrevNext: true
      }
    })

    this.slider.on("afterInit", (slider) => this.handleVideos(slider))
    this.slider.on("slideChangeTransitionEnd", (slider) => this.handleVideos(slider))

    this.slider.init()
  }

  disconnect() {
    this.slider.destroy({ completely: true })
    this.slider = null
  }

  handleVideos(slider) {
    // Pause all other videos
    slider.slides.forEach(el => {
      if (el.children[0].nodeName == "VIDEO") {
        el.children[0].pause()
      }
    })

    // Play current video
    const sourceNode = slider.slides[slider.activeIndex].children[0]

    if (sourceNode.nodeName == "VIDEO") {
      sourceNode.controls = true
      sourceNode.play()
    }
  }
}
