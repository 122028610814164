import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element"]

  connect() {

  }

  open() {
    this.dialog.open = true
  }

  close() {
    this.dialog.open = false
  }

  toggle() {
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  // Private

  get dialog() {
    return this.hasElementTarget ? this.elementTarget : this.element
  }

  get isOpen() {
    return this.dialog.open
  }
}
